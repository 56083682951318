// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-index-mdx": () => import("/tmp/2d7e0c78/src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---readme-md": () => import("/tmp/2d7e0c78/README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-color-overview-mdx": () => import("/tmp/2d7e0c78/src/color/overview.mdx" /* webpackChunkName: "component---src-color-overview-mdx" */),
  "component---src-components-alerts-mdx": () => import("/tmp/2d7e0c78/src/components/Alerts.mdx" /* webpackChunkName: "component---src-components-alerts-mdx" */),
  "component---src-components-call-out-boxes-mdx": () => import("/tmp/2d7e0c78/src/components/Call-Out-Boxes.mdx" /* webpackChunkName: "component---src-components-call-out-boxes-mdx" */),
  "component---src-components-forms-mdx": () => import("/tmp/2d7e0c78/src/components/Forms.mdx" /* webpackChunkName: "component---src-components-forms-mdx" */),
  "component---src-components-grid-system-mdx": () => import("/tmp/2d7e0c78/src/components/Grid-System.mdx" /* webpackChunkName: "component---src-components-grid-system-mdx" */),
  "component---src-components-horizontal-rule-mdx": () => import("/tmp/2d7e0c78/src/components/Horizontal-Rule.mdx" /* webpackChunkName: "component---src-components-horizontal-rule-mdx" */),
  "component---src-components-icons-mdx": () => import("/tmp/2d7e0c78/src/components/Icons.mdx" /* webpackChunkName: "component---src-components-icons-mdx" */),
  "component---src-components-images-mdx": () => import("/tmp/2d7e0c78/src/components/Images.mdx" /* webpackChunkName: "component---src-components-images-mdx" */),
  "component---src-components-maps-mdx": () => import("/tmp/2d7e0c78/src/components/Maps.mdx" /* webpackChunkName: "component---src-components-maps-mdx" */),
  "component---src-components-media-breakpoints-mdx": () => import("/tmp/2d7e0c78/src/components/Media-Breakpoints.mdx" /* webpackChunkName: "component---src-components-media-breakpoints-mdx" */),
  "component---src-components-navigation-mdx": () => import("/tmp/2d7e0c78/src/components/Navigation.mdx" /* webpackChunkName: "component---src-components-navigation-mdx" */),
  "component---src-components-table-layouts-mdx": () => import("/tmp/2d7e0c78/src/components/Table-Layouts.mdx" /* webpackChunkName: "component---src-components-table-layouts-mdx" */),
  "component---src-components-video-mdx": () => import("/tmp/2d7e0c78/src/components/Video.mdx" /* webpackChunkName: "component---src-components-video-mdx" */),
  "component---src-components-z-index-mdx": () => import("/tmp/2d7e0c78/src/components/Z-Index.mdx" /* webpackChunkName: "component---src-components-z-index-mdx" */),
  "component---src-components-thrid-party-and-vendor-integrations-mdx": () => import("/tmp/2d7e0c78/src/components/thrid-party-and-vendor-integrations.mdx" /* webpackChunkName: "component---src-components-thrid-party-and-vendor-integrations-mdx" */),
  "component---src-typography-buttons-mdx": () => import("/tmp/2d7e0c78/src/typography/Buttons.mdx" /* webpackChunkName: "component---src-typography-buttons-mdx" */),
  "component---src-typography-headers-mdx": () => import("/tmp/2d7e0c78/src/typography/Headers.mdx" /* webpackChunkName: "component---src-typography-headers-mdx" */),
  "component---src-typography-links-mdx": () => import("/tmp/2d7e0c78/src/typography/Links.mdx" /* webpackChunkName: "component---src-typography-links-mdx" */),
  "component---src-typography-lists-mdx": () => import("/tmp/2d7e0c78/src/typography/Lists.mdx" /* webpackChunkName: "component---src-typography-lists-mdx" */),
  "component---src-typography-overview-mdx": () => import("/tmp/2d7e0c78/src/typography/Overview.mdx" /* webpackChunkName: "component---src-typography-overview-mdx" */),
  "component---src-typography-paragraph-mdx": () => import("/tmp/2d7e0c78/src/typography/Paragraph.mdx" /* webpackChunkName: "component---src-typography-paragraph-mdx" */),
  "component---src-pages-404-js": () => import("/tmp/2d7e0c78/.docz/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

